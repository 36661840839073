import React from "react";
import Layout from "../components/layouts/layout";
//Image Banner
import { BASE_SITE_URL } from "../shared/constants";

const CookiePolicy = () => {
  return (
    <Layout
      seo={{
        title: "Cookie Policy",
        ogtitle: "Cookie Policy",
        description: "Welcome to Cookie Policy Page",
        ogurl: `${BASE_SITE_URL}/cookie-policy`,
      }}
    >
      <div className="mt-70">.</div>
      <div className="mt-70 body-container">
        <div className="title-header">
          <span className="red-line3letter">COO</span>KIE POLICY
        </div>
      </div>

      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">Wha</span>t Are Cookies
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          Cookies are small text files that are placed on your computer or
          mobile device when you visit our website. They help provide a better
          user experience and collect information about your browsing
          activities.
        </div>
      </div>
      <div className="mt-30 body-container">
        <table className="table-style">
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>
              Essential Cookies
            </td>
            <td>
              On our website, these cookies play a crucial role in ensuring
              proper functionality, allowing you to navigate the site smoothly
              and access its features.
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>
              Analytical Cookies
            </td>
            <td>
              We use analytical cookies, including those provided by Google
              Analytics, to gather information about how visitors use our
              website. This helps us understand user interactions, improve our
              content, and enhance user experience.
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>Cookies</td>
            <td>
              We use a chatbot on our website to provide real-time assistance
              and answer your questions.
            </td>
          </tr>
        </table>
      </div>
      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">Thi</span>rd Party Tools we use
        </div>
      </div>
      <div className="mt-30 body-container">
        <table className="table-style">
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>
              Google Analytics
            </td>
            <td>
              We use Google Analytics to analyse website usage, track visitors,
              and display relevant ads. The cookies collect anonymous data,
              including visitor numbers, referral sources, and visited pages.
              For more details, check &nbsp;
              <a
                rel="noreferrer"
                className="atagclass"
                target="_blank"
                href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              >
                Google Analytics' Privacy Policy.
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>Lead Forensic</td>
            <td>
              We use Lead Forensics, a third-party tool to track visitors, their
              visits, and page views for better website insights. Learn more in
              their &nbsp;
              <a
                rel="noreferrer"
                className="atagclass"
                target="_blank"
                href="https://www.leadforensics.com/privacy-policy/"
              >
                Privacy Policy.
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>
              Cookie Notice Acceptance
            </td>
            <td>
              We utilise this cookie to identify whether the user has accepted
              our cookie notice rules.
            </td>
          </tr>
          <tr>
            <td style={{ fontWeight: 800, fontSize: "18px" }}>
              Chatbot Session
            </td>
            <td>
              This cookie is employed to maintain chatbot history, ensuring that
              the chat dialog is restored even after page reloads.
            </td>
          </tr>
        </table>
      </div>
      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">Man</span>aging Cookie Preferences:
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          You can control and manage cookies in your browser settings. Most
          browsers allow you to refuse cookies or delete specific cookies.
          However, please note that blocking essential cookies may impact the
          functionality of our website.
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">Dat</span>a Protection:
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          For information about how we handle data collected through cookies,
          including any data shared with third-party services, please review our
          &nbsp;
          <a
            rel="noreferrer"
            className="atagclass"
            target="_blank"
            href="/privacy-policy/"
          >
            Privacy policy
          </a>
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="image-banner-title1">
          <span className="red-line3letter">Con</span>tact Us :
        </div>
      </div>
      <div className="mt-30 body-container">
        <div className="article-main">
          If you have any questions or concerns about our cookies policy or data
          practices, please contact us at &nbsp;
          <a href="mailto:privacy@digiryte.com" className="atagclass">
            privacy@digiryte.com
          </a>
          .
        </div>
      </div>
    </Layout>
  );
};

export default CookiePolicy;
